import React, { useContext, useState, useEffect } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { 
  ProjectDeleteModal,
  DesktopAddModal,
  BrowserAddModal,
  CommunicateAddModal,
  HighlightedProjectAddButton,
  HighlightedProject,
  ProjectsTable,
  ProjectAddModal,
  ProfileAddModal,
  Project, 
} from "./../../components";

import { 
  authContextHelper, 
  api,
  bsgCrypto, 
  variables,
  helpers 
} from './../../utilities';

import {
  resourceAddIcon,
  projectIcon
} from "./../../assets/images";



/**
 * Projects HOC
 * @param props 
 * @returns HOC markup for projects
 */
const Projects: React.FunctionComponent = (props: any) => {
  const defaultContentPaneTabKeys: string[] = ["project.Details", "project.Vault", "project.Notes"]
  const { setLoaded, urlParameters, currentHistory } = props;
  const id = urlParameters.get("id");
  const auth = useContext(authContextHelper.AuthContext);
  const [projects, setProjects] = useState<any>([]);
  const [groups, setGroups] = useState<any>([]);
  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [selectedProjectNotes, setSelectedProjectNotes] = useState<any>([]);
  
  const [selectedProjectLinkedProducts, setSelectedProjectLinkedProducts] = useState<any>([]);
  const [selectedProjectLinkedProfiles, setSelectedProjectLinkedProfiles] = useState<any>([]);


  const [selectedProjectVault, setSelectedProjectVault] = useState<bsgCrypto.Vault | null>(null);
  const [decryptedVaultKey, setDecryptedVaultKey] = useState<any>(null);
  const [preSelectedSecretId, setPreSelectedSecretId] = useState<string>("")

  const [projectsListDeleteObject, setProjectsListDeleteObject] = useState<any>(null);

  const [isShowDeleteProjectModal, setDeleteProjectModalShow] = useState(false);
  const [isShowAddProjectModal, setAddProjectModalShow] = useState(false);
  const [isShowProjectAddDesktopModal, setShowProjectAddDesktopModal] = useState(false);
  const [isShowProjectAddBrowserModal, setShowProjectAddBrowserModal] = useState(false);
  const [isShowProjectAddCommunicateModal, setShowProjectAddCommunicateModal] = useState(false);
  const [isShowProjectAddProfileModal, setShowProjectAddProfileModal] = useState(false);



  const [addProjectModalSubmitError, setAddProjectModalSubmitError] = useState<any>(null);
  const [projectVaultPasswordSubmitError, setProjectVaultPasswordSubmitError] = useState<any>(null);
  const [projectVaultSecretEditSubmitError, setProjectVaultSecretEditSubmitError] = useState<any>(null);
  const [projectAddDesktopModalSubmitError, setProjectAddDesktopModalSubmitError] = useState<any>(null);
  const [projectAddBrowserModalSubmitError, setProjectAddBrowserModalSubmitError] = useState<any>(null);
  const [projectAddCommunicateModalSubmitError, setProjectAddCommunicateModalSubmitError] = useState<any>(null);
  const [projectAddProfileModalSubmitError, setProjectAddProfileModalSubmitError] = useState<any>(null);

  const [projectContentActiveProducts, setProjectContentActiveProducts] = useState<any>([]);
  const [projectContentPaneTabsActiveKey, setProjectContentPaneTabsActiveKey] = useState<string>("project.Detail");

  //console.log("PROJECTS: USER KEYS FROM CONTEXT: ", auth.userKeys);


  const handleProjectEditSecurityGroup = async(event: any, query: any) => {
    event.preventDefault();
    
    const submitResponse =  await api.putResourceSecurityGroup({
      resourceId: selectedProject.id, 
      securityGroupID: query.id, 
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PROJECT.API_KEY
    });

    if ( submitResponse.result === "Failed"){
      toast.warn(submitResponse.error, {
        position: "top-right",
        autoClose: 8000,
        theme: "light",
        });
    } else if ( submitResponse.result === "Success"){
      // const newName = query.name;
      // setGroupName(newName);
      getProjects();
      toast.success('Security Group has been changed successfully', {
        position: "top-right",
        autoClose: 5000,
        theme: "light",
        });
    } else {
      toast.warn(submitResponse.error, {
        position: "top-right",
        autoClose: 8000,
        theme: "light",
        });
    }
  }

  const handleProjectLinkedProductSelected = (event: any, productID: string) => {
    event.preventDefault();
    ////console.log("Aactive products : ", projectContentActiveProducts)
    ////console.log("ACTIVATING... : ", productID)
    // check if in current active products
    const productWithIdLength = projectContentActiveProducts.filter((product: any) => product.id === productID).length;
    ////console.log("ACTIVATING... : ", productWithIdLength)

    if(productWithIdLength === 0){
      const activeProductToAdd =  selectedProjectLinkedProducts.filter((product: any) => product.id === productID)[0];
      ////console.log("ACTIVATING... : ", activeProductToAdd)

      setProjectContentActiveProducts([...projectContentActiveProducts, activeProductToAdd]);
    }

    // handleProjectContentPaneTabsToggle(productID);
    setProjectContentPaneTabsActiveKey(productID)
  }

  const handleProjectLinkedProfileSelected = (event: any, profileID: string) => {
    event.preventDefault();
    //console.log("Active profile : ", profileID);
  }

  const handleProjectContentPaneTabsToggle = (activeKey: string) => {
    // check if product object exists
    const productCheckLength = projectContentActiveProducts.filter((product: any) => product.id === activeKey).length;

    if (productCheckLength === 1 || defaultContentPaneTabKeys.includes(activeKey)){
      setProjectContentPaneTabsActiveKey(activeKey)
    }
  }

  const handleProjectDelete = (event: any) => {
    event.preventDefault();

    setDeleteProjectModalShow(true)
  }

  const handleProjectListDelete = (event: any, projectsListDeleteObjectItem: any) => {
    event.preventDefault();

    setDeleteProjectModalShow(true)
    setProjectsListDeleteObject(projectsListDeleteObjectItem)
  }

  const handleCloseDeleteProjectModal = (event: any) => {
    // event.preventDefault();
    setDeleteProjectModalShow(false)
  };

  const handleCloseProjectAddModals = (event: any) => {
    // event.preventDefault();
    setShowProjectAddBrowserModal(false);
    setShowProjectAddDesktopModal(false);
    setShowProjectAddCommunicateModal(false);
    setShowProjectAddProfileModal(false);
  };


  const handleShowProjectAddDesktopModal = (event: any) => {
    // event.preventDefault();
    setShowProjectAddDesktopModal(true)
  };

  const handleShowProjectAddBrowserModal = (event: any) => {
    // event.preventDefault();
    setShowProjectAddBrowserModal(true)
  };

  const handleShowProjectAddCommunicateModal = (event: any) => {
    // event.preventDefault();
    setShowProjectAddCommunicateModal(true)
  };

  const handleShowProjectAddProfileModal = (event: any) => {
    // event.preventDefault();
    setShowProjectAddProfileModal(true)
  };

  const handleProjectDeleteSubmit = async() => {
    // setLoaded(false);
    const sessionTokens = await auth.getApiSessionTokens();

    const { result } =  await api.deleteProject({
      body: {
        id: id
      }, 
      user: sessionTokens
    });

    if (result === "Success"){
      handleCloseDeleteProjectModal(true);
      currentHistory.push("/");
    }
    // setLoaded(true);
  }

  const handleProjectListDeleteSubmit = async() => {
    // setLoaded(false);
    const sessionTokens = await auth.getApiSessionTokens();
    //console.log("delete object: ", projectsListDeleteObject);
    const { result } =  await api.deleteProject({
      body: {
        id: projectsListDeleteObject && projectsListDeleteObject.id
      }, 
      user: sessionTokens
    });

    if (result === "Success"){
      handleCloseDeleteProjectModal(true);
      // currentHistory.push("/projects");
      window.location.reload();
    }
    // setLoaded(true);
  }

  const getSelectedProjectVault = async() => {
    //console.log("Resource Vault: Refresh: 1: ID:, ", id);
    //console.log("Resource Vault: Refresh: 1: Dycrypted Vault: ", decryptedVaultKey);

    if(id && decryptedVaultKey){
      const projectVaultData = await api.getResourceVault({
        resourceId: id,
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PROJECT.API_KEY,
      });

      //console.log("Resource Vault: Refresh: 2: Response: ", projectVaultData);
      //console.log("Resource Vault: Refresh: 2: Data: ", projectVaultData.data);

      const projectVaultRefreshed = new bsgCrypto.Vault(projectVaultData.data, decryptedVaultKey);
      //console.log("Resource Vault: Refresh: 3: Data Updated: ", projectVaultRefreshed);
      
      setSelectedProjectVault(projectVaultRefreshed);
    }
  }

  const getProjects = async() => {
    setLoaded(false);
    if(id){
      const projectData = await api.getProject({
        body: {
          id: id
        }
      });

      setSelectedProject(projectData);

      // Get Selected project linked products 
      // Set products in state if project valid
      const projectProductsData = await api.getProducts({
        filter: projectData.id
      });
      setSelectedProjectLinkedProducts(projectProductsData.sort((a: { modified: number; }, b: { modified: number; }) => (a.modified < b.modified) ? 1 : -1));
      
      // Get Project Linked Profiles 
      const projectProfilesData = await api.getProfiles({filter: projectData.id});
      setSelectedProjectLinkedProfiles(projectProfilesData.sort((a: { modified: number; }, b: { modified: number; }) => (a.modified < b.modified) ? 1 : -1));
    }

    const projectsData = await api.getProjects({});
    const groupsData  = await api.getSecurityGroups({});
    
    setGroups(groupsData);
    setProjects(projectsData);

    setLoaded(true);
  }

  const handleShowAddProjectModal = (event: any) => {
    // event.preventDefault();
    setAddProjectModalShow(true)
  };

  const handleCloseAddProjectModal = (event: any) => {
    // event.preventDefault();
    setAddProjectModalShow(false)
  };


  const handleProjectAddProductSubmit = async(query: any) => {
    //console.log("PROJECT: ADD PRODUCT MODAL: Query: ", query);
    //console.log("PROJECT: ADD PRODUCT MODAL: Product Type: ", query.productType);

    const vaultKeys = query.body.security ? 
      await helpers.createVaultKeysForSecurityGroup(query.body.security) : 
      await bsgCrypto.VaultKey.createVaultKey([{username: auth.userProfile.username, publicKey: auth.userKeys!.publicKey}]);
      
    const submitResponse =  await api.postProduct({
      body: query.body, 
      productType: query.productType,
      user: auth.userProfile.username, 
      vaultKeys: vaultKeys
    });

    if ( submitResponse.result === "Failed"){
      switch(query.productType){
        case variables.DEFAULT_PRODUCT_TYPES.DESKTOP.API_KEY:
          return setProjectAddDesktopModalSubmitError(submitResponse.error);

        case variables.DEFAULT_PRODUCT_TYPES.BROWSER.API_KEY:
          return setProjectAddBrowserModalSubmitError(submitResponse.error);
        
        case variables.DEFAULT_PRODUCT_TYPES.VTC.API_KEY:
          return setProjectAddCommunicateModalSubmitError(submitResponse.error);
        
        case variables.DEFAULT_PRODUCT_TYPES.CHAT.API_KEY:
          return setProjectAddCommunicateModalSubmitError(submitResponse.error);
        
        default:
          return console.log("PROJECT: ADD PRODUCT MODAL: ERROR: ", submitResponse.error);

      }
    } else if ( submitResponse.result === "Success"){

      handleCloseProjectAddModals(null);

      toast.success(`${query.body.name} has been created successfully`, {
        position: "top-right",
        autoClose: 5000,
        theme: "light",
      });

      // currentHistory.push(`/projects?id=${selectedProject.id}`);
      window.location.reload();

    } else {

      //console.log("ERROR RESPONSE: PROJECT PRODUCTS POST: ", submitResponse);

    }
  }

  const handleProjectAddProfileSubmit = async(query: any) => {
    const sessionTokens = await auth.getApiSessionTokens();

    //console.log("attempting profile submit : ", query);

    // Generate Profile Vault
    // const vaultKey = await bsgCrypto.VaultKey.createVaultKey(auth.userKeys!.publicKey);
    const vaultKeys = await bsgCrypto.VaultKey.createVaultKey([{ username: auth.userProfile.username, publicKey: auth.userKeys!.publicKey}]);

    const submitResponse =  await api.postProfile({
      body: query.body, 
      user: sessionTokens,
      vaultKeys: vaultKeys
    });

    //console.log("submit response: ", submitResponse)

    if ( submitResponse.result === "Failed"){
      setProjectAddProfileModalSubmitError(submitResponse.error);

    } else if ( submitResponse.result === "Success"){
      handleCloseProjectAddModals(null);

      toast.success(`${query.body.name} has been created successfully`, {
        position: "top-right",
        autoClose: 3000,
        theme: "light",
      });

      window.location.reload();
    } else {

      //console.log("ERROR RESPONSE: PROFILES PUT: ", submitResponse);
    }
  }

  const handleProjectAddSubmit = async(query: any) => {
    //const sessionTokens = await auth.getApiSessionTokens();

    //console.log("attempting project submit : ", query);

    const vaultKeys = query.body.security ? 
      await helpers.createVaultKeysForSecurityGroup(query.body.security) : 
      await bsgCrypto.VaultKey.createVaultKey([{username: auth.userProfile.username, publicKey: auth.userKeys!.publicKey}]);

    const submitResponse =  await api.postProject({
      body: query.body, 
      user: auth.userProfile.username,
      vaultKeys: vaultKeys
    });

    //console.log("submit response: ", submitResponse)

    if ( submitResponse.result === "Failed"){
      setAddProjectModalSubmitError(submitResponse.error);

    } else if ( submitResponse.result === "Success"){
      
      handleCloseAddProjectModal(null);

      currentHistory.push(`/projects?id=${submitResponse.id}`);

    } else {

      //console.log("ERROR RESPONSE: PROJECTS PUT: ", submitResponse);

    }
  }

  const getSelectedProjectNotes = async() => {
    if(id){
      const projectNotesData = await api.getResourceNotes({
        resourceId: id, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PROJECT.API_KEY
      });
      setSelectedProjectNotes(projectNotesData && projectNotesData.sort((a: { modified: number; }, b: { modified: number; }) => (a.modified < b.modified) ? 1 : -1));
    }
  }

  const handleGetProjectNote = async(event: any) => {
    const noteData = await api.getResourceNote({resourceId: id, noteId: event.noteID, resourceType: variables.DEFAULT_RESOURCE_TYPES.PROJECT.API_KEY});
    
    if(noteData){
      event.editorRef.current.setContent(noteData);
      event.editorRef.current.id = event.noteID;
    }
  }

  const handlePutProjectNote = async(event: any) => {

    await api.putResourceNote({
      resourceId: id, 
      noteId: event.id, 
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PROJECT.API_KEY,
      body: event.body,
      headline: event.headline, 
      title: event.title
    });
  }

  const handlePostProjectNote = async(event: any) => {
    await api.postResourceNote({
      resourceId: id,
      body: 'New Note', 
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PROJECT.API_KEY, 
      headline: 'New Note', 
      title: 'New Note'
    });

    getSelectedProjectNotes();
  }

  const handleDeleteProjectNote = async(event: any) => {
    await api.deleteResourceNote({
      resourceId: id, 
      noteId: event.id,
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PROJECT.API_KEY, 
    });

    getSelectedProjectNotes();
  }

  const handleProjectsListToggleFavorite = async(event: any) => {
    const toggleResponse = await api.toggleResourceFavourite({
      resourceId: event.resourceId, 
      toggleType: event.toggleType, 
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PROJECT.API_KEY
    });
    //console.log("BROWSER: Toggle : ", toggleResponse);

    await getProjects();

    toast.success('Favorites have been updated successfully', {
      position: "top-right",
      autoClose: 3000,
      theme: "light",
    });
  }


  const handleDycryptVaultSubmit = async(query: any) => {
    const projectVaultData = await api.getResourceVault({
      resourceId: id,
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PROJECT.API_KEY,
    });

    //console.log("Resource Vault: 1: ", projectVaultData.data);
    const base64EncodedVaultKey = projectVaultData.data.key[auth.userProfile.username]!;
    //console.log("Resource Vault: 2: ", projectVaultData.data);
    const myVaultKey = bsgCrypto.VaultKey.fromBase64(base64EncodedVaultKey);
    //console.log("Resource Vault: 3: ", projectVaultData.data);
    
    const unwrappedVaultKey = await bsgCrypto.UnwrappedVaultKey.fromWrappedVaultKey(
      myVaultKey,
      query.body.password,
      auth.userKeys!.wrappedPrivateKey
    );

    //console.log("Resource Vault: 4: ", projectVaultData.data);
    
    if(unwrappedVaultKey){
      setDecryptedVaultKey(unwrappedVaultKey);

      const projectVaultRefreshed = new bsgCrypto.Vault(projectVaultData.data, unwrappedVaultKey);
      //console.log("Resource Vault: 5: Data Updated: ", projectVaultRefreshed);
      
      setSelectedProjectVault(projectVaultRefreshed);
    } else {
      setProjectVaultPasswordSubmitError("Password Error: Enter Valid Password");
    }
  };

  const handlePostProjectVaultPassword = async(event: any) => {
    const postResponse = await api.postResourceVaultPassword({
      resourceId: id,
      body: '<h1> New Password </h1>', 
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PROJECT.API_KEY, 
      headline: 'New Password', 
      title: 'New Password'
    });

    //console.log("Creating new password: 1 : ", postResponse);

    if(postResponse.data && postResponse.data.id){
      const passwordChangeset = await selectedProjectVault!.addOrUpdateItemInVault(
        "My new password",
        new bsgCrypto.SecretMetadata(postResponse.data.id, "My New Password Title", "Username")
      );

      //console.log("Creating new password: 2 : Changeset: ", passwordChangeset); 

      const passwordChangeResponse = await api.patchResourceVaultPassword({
        resourceId: id, 
        body: passwordChangeset, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PROJECT.API_KEY, 
      });

      //console.log("Creating new password: 3 : Change Patch: ", passwordChangeResponse); 
    }

    await getSelectedProjectVault();
  }


  const handleDeleteProjectVaultPassword = async(query: any) => {
    //console.log("Deleting password: 1 : ", query);

    if(query.body && query.body.secretId){

      const passwordChangeset = await selectedProjectVault!.removeItemFromVault(query.body.secretId);

      //console.log("Deleting password: 2 : Changeset: ", passwordChangeset); 

      const passwordChangeResponse = await api.patchResourceVaultPassword({
        resourceId: id, 
        body: passwordChangeset, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PROJECT.API_KEY, 
      });

      //console.log("Deleting password: 3 : Change Patch: ", passwordChangeResponse); 

      if (passwordChangeResponse && passwordChangeResponse.result === "Success") {

        const apiResponse = await api.deleteResourceVaultPassword({
          resourceId: id, 
          resourceType: variables.DEFAULT_RESOURCE_TYPES.PROJECT.API_KEY,
          secretId: query.body.secretId
        });

        //console.log("Deleting password: 4 : API Response: ", apiResponse); 
      }
    }

    await getSelectedProjectVault();
  }


  const handleEditProjectVaultPassword = async(query: any) => {
    //console.log("Editing password: 1 : ", query);

    if(query.body && query.body.secretId){
      //console.log("Deleting password: 2 : ", query); 
      
      setPreSelectedSecretId(query.body.secretId);
      
      const passwordChangeset = await selectedProjectVault!.addOrUpdateItemInVault(query.password, query.body);

      //console.log("Deleting password: 3 : Changeset: ", passwordChangeset); 

      const passwordChangeResponse = await api.patchResourceVaultPassword({
        resourceId: id, 
        body: passwordChangeset, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PROJECT.API_KEY,
      });

      //console.log("Deleting password: 4 : Change Patch: ", passwordChangeResponse); 
      
    }

    await getSelectedProjectVault();
  }

  // On mount: hook effect
  useEffect(() =>{

    // Fetch All Data
    getProjects();
    getSelectedProjectNotes();
    setProjectsListDeleteObject(null);
  },[id]);



  if(id){
    // Check if Selected project state value is not null
    // Render Selected project with success res object as props, else redirect to 404 page
    
    return (
      <>
        {(selectedProject) && (
          <>
            <ProjectDeleteModal
                show={isShowDeleteProjectModal}
                handleClose={handleCloseDeleteProjectModal}
                handleModalFormSubmit={handleProjectDeleteSubmit}
                name={selectedProject.name}
              />
            
            <DesktopAddModal
              show={isShowProjectAddDesktopModal}
              submitError={projectAddDesktopModalSubmitError}
              handleClose={handleCloseProjectAddModals}
              handleModalFormSubmit={handleProjectAddProductSubmit}

              linkedProjectId={selectedProject.id}
              securityGroups={groups}
            />

            <BrowserAddModal
              show={isShowProjectAddBrowserModal}
              submitError={projectAddBrowserModalSubmitError}
              handleClose={handleCloseProjectAddModals}
              handleModalFormSubmit={handleProjectAddProductSubmit}

              linkedProjectId={selectedProject.id}
              securityGroups={groups}
            />

            <CommunicateAddModal
              show={isShowProjectAddCommunicateModal}
              submitError={projectAddCommunicateModalSubmitError}
              handleClose={handleCloseProjectAddModals}
              handleModalFormSubmit={handleProjectAddProductSubmit}

              linkedProjectId={selectedProject.id}
              securityGroups={groups}
            />

            <ProfileAddModal
              show={isShowProjectAddProfileModal}
              submitError={projectAddProfileModalSubmitError}
              handleClose={handleCloseProjectAddModals}
              handleModalFormSubmit={(query: any) => handleProjectAddProfileSubmit(query)}

              linkedProjectId={selectedProject.id}
              groups={groups}
            />

            <Project
              projectDetails={selectedProject} 
              mediaControlHandlers={{
                handleProjectDelete: handleProjectDelete,
              }}

              groups={groups}
              projectNotes={selectedProjectNotes}
        
              projectLinkedProducts={selectedProjectLinkedProducts}
              projectLinkedProfiles={selectedProjectLinkedProfiles}
              
              projectContentPaneTabsActiveKey={projectContentPaneTabsActiveKey}
              projectContentActiveProducts={projectContentActiveProducts}
              
              handleProjectEditSecurityGroup={handleProjectEditSecurityGroup}
              handleProjectLinkedProductSelected={handleProjectLinkedProductSelected}
              handleProjectLinkedProfileSelected={handleProjectLinkedProfileSelected}
              handleProjectContentPaneTabsToggle={handleProjectContentPaneTabsToggle}
            

              // Modal Controls: Profile Products
              projectModalHandlers={{
                handleShowAddDesktopModal: handleShowProjectAddDesktopModal,
                handleShowAddBrowserModal: handleShowProjectAddBrowserModal,
                handleShowAddCommunicateModal: handleShowProjectAddCommunicateModal,
                handleShowAddProfileModal: handleShowProjectAddProfileModal,
              }}


              // Note handlers
              projectNoteHandlers={{
                handleGetProjectNote: handleGetProjectNote,
                handlePutProjectNote: handlePutProjectNote,
                handlePostProjectNote: handlePostProjectNote,
                handleDeleteProjecteNote: handleDeleteProjectNote,
                handleGetProjectNotes: getSelectedProjectNotes
              }}

              // Vault Handlers
              projectVaultHandlers={{
                handleDycryptVaultSubmit: handleDycryptVaultSubmit,
                handlePostVaultPassword: handlePostProjectVaultPassword,
                handleDeleteVaultPassword: handleDeleteProjectVaultPassword,
                handleEditVaultPassword: handleEditProjectVaultPassword,

                setPreSelectedSecretId: setPreSelectedSecretId,

                vaultPasswordSubmitError: projectVaultPasswordSubmitError,
                vaultSecretEditSubmitError: projectVaultSecretEditSubmitError,
                decryptedVaultKey: decryptedVaultKey,
                preSelectedSecretId: preSelectedSecretId,

                projectVault: selectedProjectVault
                
              }}
            />
        </>
        
        )}
      </>
    )
  } else {
    return (
      <>
        <ProjectDeleteModal
          show={isShowDeleteProjectModal}
          handleClose={handleCloseDeleteProjectModal}
          handleModalFormSubmit={handleProjectListDeleteSubmit}
          name={projectsListDeleteObject && projectsListDeleteObject.name}
        />
        <ProjectAddModal
          show={isShowAddProjectModal}
          submitError={addProjectModalSubmitError}
          groups={groups}
          handleClose={handleCloseAddProjectModal}
          handleModalFormSubmit={handleProjectAddSubmit}
        />
        <div>
          <Row>
            <Col md={4}><h1> Project Overview </h1></Col>
          </Row>
          <h5>Hello {auth.userProfile.username}, all of your projects are shown below.</h5>
        </div>

        <Container className="ps-3 ms-0 pt-3"> 
          <div className="pt-0">
            <div>
              <Row>
                {projects && projects.map((project: any, index: number) => {
                  return (
                    <HighlightedProject key={`HR-${index}`}
                      header={project.name}
                      description={project.description}
                      image={projectIcon}
                      linkPath={`/projects?id=${project.id}`}
                      status={project.state}
                    />
                  )
                })}

              </Row>
              <Row>
                <HighlightedProjectAddButton
                  clickHandler={handleShowAddProjectModal}
                  header={"Add Project"}
                  description={"Create a new project"}
                  image={resourceAddIcon}
                />
              </Row>
              <br/>
              <Row>
                <Row>
                  <div className="ps-0">
                    <h6> All Projects </h6>
                  </div>
                </Row>
                <Row>
                  <ProjectsTable 
                    projects={projects} 
                    mediaControlHandlers={{
                      handleProjectsListDelete: handleProjectListDelete,
                      handleProjectsListToggleFavorite: handleProjectsListToggleFavorite
                    }}
                />
                </Row>
              </Row>
            </div>
          </div>
        </Container>
      </>
    );
  }
};



export default Projects;